import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["post", "presetButton"]

  connect() {
    this.insertButton()
  }

  insertButton() {
    const presetButtonElement = this.presetButtonTarget

    const postElement = this.postTarget
    const divs = postElement.querySelectorAll("div")
    if (divs.length >= 3) {
      divs[2].insertAdjacentElement("afterend", presetButtonElement)
    } else {
      postElement.appendChild(presetButtonElement)
    }

    presetButtonElement.style.display = "block"
  }
}
